<template>
  <div class="yos-main-menu overflow-auto">
    <div
      v-for="(item,idx) in menu"
      :key="idx"
      class="item"
    >
      <router-link
        v-if="item.path"
        :class="`title ${item.active ? 'router-link-active' : ''}`"
        :to="item.path"
      >
        <i
          class="icon iconfont"
          :class="[item.icon]"
        />
        <span>{{ item.name }}</span>
      </router-link>
      <a
        v-else
        class="title"
      >
        <i
          class="icon iconfont"
          :class="[item.icon]"
        />
        <span>{{ item.name }}</span>
      </a>
      <router-link
        v-for="entry in item.children"
        :key="entry.path"
        :class="`entry ${entry.active ? 'router-link-active' : ''}`"
        :to="!entry.path ? 'javascript:void(0)' : entry.path"
      >
        <span class="icon" />
        <span>{{ entry.name }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { getLocalUser, globalRoute } from '../../util'
export default {
  setup () {
    const menu = ref([])
    const getTreeData = (data, parentId) => {
      const newData = []
      const childrenData = []

      data.forEach(item => {
        if (!parentId ? !item.parentId : item.parentId === parentId) {
          newData.push(item)
        } else {
          childrenData.push(item)
        }
      })

      function formatData (data, children) {
        data.forEach(item => {
          let next = []
          let other = []

          children.forEach(entry => {
            if (entry.parentId === item.id) {
              next.push(entry)
            } else {
              other.push(entry)
            }
          })

          if (next.length > 0) {
            item.children = next
            formatData(item.children, other)
          }
        })
      }

      formatData(newData, childrenData)
      return newData
    }

    const isParentMenu = (right) => {
      return right.parentId === -1 && !/^(\/|\/login|\/|\/lock|\/right)$/.test(right.path)
    }
    const rights = (getLocalUser().rights || []).filter(item => item.type === 1)

    menu.value = getTreeData(rights, -1).filter(item => {
      return !(isParentMenu(item) && !(Array.isArray(item.children) && item.children.length > 0))
    })
    const path = window.location.pathname

    const setMenu = (path) => {
      menu.value = menu.value.map(item => {
        item.active = false

        if (isParentMenu(item)) {
          item.path = item.children[0].path

          item.children = item.children.map(entry => {
            entry.active = false
            if (path.indexOf(entry.path + '/') !== -1) {
              entry.active = true
            }
            if (entry.path.split('/')[1] === path.split('/')[1]) {
              item.active = true
            }
            if (entry.path === '/setting/accounts' && /\/setting\/roles/.test(path)) {
              entry.active = true
            }
            if (entry.path === '/fun/send_templates' && /\/fun\/template/.test(path)) {
              entry.active = true
            }
            return entry
          })
        }

        return item
      })
    }

    setMenu(path)

    Object.defineProperty(globalRoute, 'path', {
      set: (obj) => {
        setMenu(obj)
      }
    })

    return {
      menu
    }
  }
}
</script>

<style lang="postcss">
.yos-main-menu {
  width: 290px;
  padding: 30px 40px 30px 50px;
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  .icon {
    font-size: 26px;
    margin: 3px 10px 3px 5px;
    color: #999;
    vertical-align: -3px;
  }

  .item {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;

    .title {
      font-size: 16px;
      display: block;
      line-height: 50px;
    }

    .entry {
      font-size: 14px;
      display: block;
      line-height: 40px;

      &.disabled {
        color: #ccc;
        cursor: default;
      }
    }

    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
    }

    .router-link-active, .router-link-active .icon {
      color: #1aad19;
    }

    &:first-child .router-link-active {
      color: #666;
    }

    &:first-child .router-link-active {
      color: #1aad19;
    }
  }
}
</style>
