<template>
  <div class="ui-user-info">
    <div class="fix" @click="onClick">
      <i class="icon-pull-down" />
      <div class="info">
        <p class="name">{{ user.name }}</p>
        <p class="role elis" :title="user.roleNames">{{ user.roleNames }}</p>
      </div>
      <i class="icon-avatar" />
    </div>
    <ul :class="addClass('down-box', [isOpen, 'open'])">
      <li class="item" @click="onChange">
        <i class="icon-password" />
        <span>修改密码</span>
      </li>
      <li class="item" @click="onQuit">
        <i class="icon-quit" />
        <span>退出登录</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ChangePassword from './change-password'
import { removeToken } from '../../data'
import { removeAccessToken, getLocalUser, addClass } from '../..//util'
import { inject, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useDialog } from '../../hook'
import { toRefs } from 'vue'

export default {
  setup() {
    const dialog = useDialog()
    const router = useRouter()
    const state = reactive({
      isOpen: false,
      user: getLocalUser()
    })
    const updateStore = inject('updateStore')

    const onClick = () => {
      state.isOpen = !state.isOpen
    }

    const onQuit = () => {
      state.isOpen = false
      removeToken()
      removeAccessToken()
      router.push('/login')
    }

    const onChange = () => {
      state.isOpen = false
      const currentDialog = dialog({
        title: '修改密码',
        childrenComponent: <ChangePassword />,
        okText: '',
        cancelText: ''
      })
      updateStore('currentDialog', currentDialog)
    }

    return {
      ...toRefs(state),
      addClass,
      onClick,
      onQuit,
      onChange
    }
  }
}
</script>

<style lang="postcss">
.ui-user-info {
  position: relative;
  cursor: pointer;

  .icon-avatar, .icon-pull-down, .info {
    float: right;
  }

  .icon-avatar {
    color: #f60;
    font-size: 30px;
    margin-top: 5px;
    user-select: none;
  }

  .icon-pull-down {
    margin-top: 10px;
    user-select: none;
  }

  .info {
    padding: 0 10px;
  }

  .role {
    color: #999;
    font-size: 12px;
    max-width: 93px;
  }

  .down-box {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    width: 200px;
    padding: 10px 0;
    display: none;

    &.open {
      display: block;
    }

    .item {
      font-size: 14px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #e5e5e5;
      }

      .icon-quit, .icon-password {
        font-size: 18px;
        vertical-align: -1px;
        margin-right: 5px;
      }
    }
  }
}
</style>
