import axios from 'axios'
import { getAccessToken } from './util'

const basePath = '/' // process.env.VUE_APP_API + '/point/api/client/'
const headers = {
  'Content-Type': 'application/json'
}

const CancelToken = axios.CancelToken
const source = CancelToken.source()

const instance = axios.create({
  baseURL: basePath,
  timeout: 30000,
  headers,
  validateStatus: function () {
    return true
  }
})

instance.interceptors.request.use(config => {
  const { url, headers } = config
  const token = getAccessToken()

  if (token && !headers['Authorization']) {//config.url !== '/api/token') {
    headers['Authorization'] = `Bearer ${token}`
  }

  config.url = /\?/.test(url) ? `${url}${/(&|\?)$/.test(url) ? '' : '&'}_t=${Date.now()}` : `${url}?_t=${Date.now()}`

  return config
}, error => Promise.reject(error))

instance.interceptors.response.use(response => {
  if (response.status === 401) {
    response.data = { code: 401 }
    // Vue.prototype.removeAccessToken()
    // router.push('/login')
  } else if (response.status === 403) {
    response.data = { code: 403 }
    // Vue.prototype.$alert({
    //   title: '提示',
    //   content: '您没有足够权限，请联系管理员'
    // })
  }

  return response
}, error => Promise.reject(error))

export default instance
