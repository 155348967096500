import { global, globalRoute, getLocalUser, getAccessToken } from './util'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/home.vue'),
    meta: {
      title: '首页 - 纵购公众号管理'
    }
  },
  {
    path: '/lock',
    name: 'lock',
    component: () => import('./views/lock.vue'),
    meta: {
      title: '没有权限 - 纵购公众号管理'
    }
  },
  {
    path: '/right',
    name: 'right',
    component: () => import('./views/right.vue'),
    meta: {
      title: '权限配置 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/send_templates/new',
    name: 'newSendTemplate',
    component: () => import('./views/fun/send-template.vue'),
    meta: {
      title: '创建推送模板 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/send_templates/:id',
    name: 'sendTemplate',
    component: () => import('./views/fun/send-template.vue'),
    meta: {
      title: '编辑推送模板 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/send_templates',
    name: 'sendTemplates',
    component: () => import('./views/fun/send-templates.vue'),
    meta: {
      title: '推送模板 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/template_libraries/new',
    name: 'newTemplateLibrary',
    component: () => import('./views/fun/template-library.vue'),
    meta: {
      title: '创建模板库 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/template_libraries/:id',
    name: 'templateLibrarary',
    component: () => import('./views/fun/template-library.vue'),
    meta: {
      title: '编辑模板库 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/template_libraries',
    name: 'templateLibraries',
    component: () => import('./views/fun/template-libraries.vue'),
    meta: {
      title: '模板库 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/templates/:appid/:id',
    name: 'template',
    component: () => import('./views/fun/template.vue'),
    meta: {
      title: '模板消息详情 - 纵购公众号管理'
    }
  },
  {
    path: '/fun/templates',
    name: 'templates',
    component: () => import('./views/fun/templates.vue'),
    meta: {
      title: '模板消息 - 纵购公众号管理'
    }
  },
  {
    path: '/manage/follow',
    name: 'manageFollow',
    component: () => import('./views/manage/follow.vue')
  },
  {
    path: '/setting/authorizers/:id',
    name: 'authorizer',
    component: () => import('./views/setting/authorizer.vue')
  },
  {
    path: '/setting/authorizers',
    name: 'authorizers',
    component: () => import('./views/setting/authorizers.vue')
  },
  {
    path: '/setting/accounts',
    name: 'accounts',
    component: () => import('./views/setting/accounts.vue')
  },
  {
    path: '/setting/accounts/new',
    name: 'newAccount',
    component: () => import('./views/setting/account.vue')
  },
  {
    path: '/setting/accounts/:id',
    name: 'account',
    component: () => import('./views/setting/account.vue')
  },
  {
    path: '/setting/roles/new',
    name: 'newRoles',
    component: () => import('./views/setting/role.vue')
  },
  {
    path: '/setting/roles/:id',
    name: 'role',
    component: () => import('./views/setting/role.vue')
  },
  {
    path: '/setting/roles',
    name: 'roles',
    component: () => import('./views/setting/roles.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/login.vue'),
    meta: {
      title: '登录 - 纵购公众号管理'
    }
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  globalRoute.path = to.path

  if (to.path === '/right' || to.path === '/login' || to.path === '/lock') {
    global.state = {...global.state, menuShow: false, toolbarShow: to.path === '/login' ? false : true}
  } else {
    global.state = {...global.state, menuShow: true, toolbarShow: true}
  }

  const token = getAccessToken()
  const user = getLocalUser()

  const getFullPath = (path) => {
    const arr = path.split('/').filter(item => item !== '')
    let fullPath = ''

    arr.forEach(item => {
      fullPath += '/' + (/^:/.test(item) ? to.params[item.replace(':', '')] : item)
    })

    return fullPath === '' ? '/' : fullPath
  }


  if (!token) {
    if (to.path !== '/login') {
      router.push('/login')
    }
  } else {
    if (to.path === '/login') {
      router.push(from.path ? from : '/')
    } else {
      if (to.path !== '/lock') {
        const rights = user.rights || []
        const formIsRight = rights.find(item => {
          return item.method === 'GET' && to.path === getFullPath(item.path) && (to.path === '/' || (item.parent_id !== -1 && !item.icon))
        })
        const nextTo = rights.find(item => {
          return (item.type === 1 || item.method === 'GET' && !/^\/api\//.test(item.path)) && item.parent_id !== -1 && !item.icon
        }) || {}
        let path

        if (!formIsRight) {
          path = rights.length <= 0 ? '/lock' : nextTo.path || '/'

          if (!(path === '/' && to.path === '/')) {
            router.replace(path)
          }
        }
      }
    }
  }

  if (user.rights && user.rights.length <= 0 && to.name !== 'lock' && to.name !== 'right') {
    router.replace('/lock')
  }

  next()
})

// export const gotoLogin = () => {
//   router.push(`/login?referrer=${encodeURIComponent(window.location.pathname + window.location.search)}`)
// }

export default router
