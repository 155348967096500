
import { createApp } from 'vue'
import router from './router'
import App from './app.vue'
import YosInput from './components/form/yos-input'
import YosButton from './components/form/yos-button'
import YosCheckbox from './components/form/yos-checkbox'
import './assets/fonts/style.css'

const app = createApp(App)

app.component('yos-input', YosInput)
app.component('yos-button', YosButton)
app.component('yos-checkbox', YosCheckbox)
app.use(router)
app.mount('#app')
