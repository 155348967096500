<template>
  <div class="c-change-password">
    <div class="item fix">
      <yos-input type="password" label="原密码" v-model="password" :width="360" />
    </div>
    <div class="item fix">
      <yos-input type="password" label="新密码" v-model="newPassword" :width="360" />
    </div>
    <p>修改密码后，请重新登录账号。</p>
    <div class="options">
      <yos-button
        class="yos-button-primary"
        @click="onOk"
      >
        确定
      </yos-button>
      <yos-button @click="onClose">取消</yos-button>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import YosInput from '../form/yos-input.vue'
import YosButton from '../form/yos-button.vue'
import { useServer, useToast } from '../../hook'
import { changePassword } from '../../data'
import { removeAccessToken } from '../../util'
  export default {
    components: {
      YosInput,
      YosButton
    },
    name: 'change-password',
    setup() {
      const toast = useToast()
      const password = ref('')
      const newPassword = ref('')
      const store = inject('store')
      const updateStore = inject('updateStore')

      const setChangePasswordParams = useServer(changePassword, () => {
        removeAccessToken()
        window.location.reload()
      })

      const onOk = () => {
        if (!password.value) {
          toast('请输入原密码')
          return
        }

        if (!newPassword.value) {
          toast('请输入新密码')
          return
        }

        if (newPassword.value.length < 6 || newPassword.value.length > 16) {
          toast('新密码必须在6~16位之间')
          return
        }

        if (password.value === newPassword.value) {
          toast('新密码不能和原密码相同')
          return
        }

        setChangePasswordParams({password: password.value, newPassword: newPassword.value})
      }

      const onClose = () => {
        if (!store.currentDialog) return
        store.currentDialog.close()
        updateStore('currentDialog', '')
      }

      return {
        password,
        newPassword,
        onOk,
        onClose
      }
    }
  }
</script>

<style lang="postcss">
.c-change-password {
  .item {
    margin: 10px 0;
  }

  .options {
    text-align: center;
    margin-top: 30px;

    .yos-button {
      margin: 0 5px;
    }
  }
}
</style>
