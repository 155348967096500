<template>
  <span class="yos-input">
    <label v-if="label" class="yos-input-label">{{ label }}</label>
    <span class="yos-input-inner" :style="`width: ${width}px`">
      <i v-if="icon" :class="icon" />
      <input
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :class="addClass([clear && showClear, 'yos-input-clear'], [icon, 'yos-input-icon'])"
        :disabled="disabled"
        :maxlength="maxlength"
        @input="onInput"
      />
      <!-- <i
        class="icon-close-filled"
        v-if="clear && showClear && element === 'input'"
        @mousedown="onClear"
      /> -->
    </span>
    <slot></slot>
    <p class="error" v-if="errorText">{{ errorText }}</p>
  </span>
</template>

<script>
import { addClass } from '../../util'
import { toRefs, ref } from 'vue'

export default {
  props: {
    icon: String,
    label: String,
    type: String,
    modelValue: [String, Number],
    placeholder: String,
    width: Number,
    errorText: String,
    disabled: false,
    maxlength: String
  },
  setup (props, { emit }) {
    const { icon, label, type = 'text', modelValue = '', placeholder, width, errorText } = toRefs(props)
    const value = ref(modelValue)
    const clear = ref(false)
    const showClear = ref(false)

    const onInput = (event) => {
      const val = (event.target.value || '').trim()
      emit('update:modelValue', val)
    }

    return {
      icon,
      label,
      type,
      value,
      modelValue,
      placeholder,
      width,
      errorText,
      addClass,
      clear,
      showClear,
      onInput
    }
  }
}
</script>

<style lang="postcss">
  .yos-input {
    height: 40px;
    display: inline-block;

    .yos-input-label {
      display: inline-block;
      margin-right: 5px;
    }

    &.yos-input-big {
      height: 50px;

      .yos-input-inner {
        [class^="icon-"], [class*=" icon-"] {
          line-height: 50px;
          width: 50px;
        }

        input.yos-input-icon {
          padding-left: 50px;
        }
      }
    }

    .yos-input-inner {
      display: inline-block;
      height: 100%;
      position: relative;

      [class^="icon-"], [class*=" icon-"] {
        position: absolute;
        left: 0;
        font-size: 20px;
        line-height: 40px;
        width: 40px;
        color: #999;
      }

      .icon-close-filled {
        position: absolute;
        right: 5px;
        margin-top: -8px;
        top: 50%;
        height: 16px;
        line-height: 16px;
      }

      input, textarea {
        border: 1px solid #ccc;
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 3px;
        height: 100%;
        width: 100%;
        line-height: 20px;

        &::-ms-clear, &::-ms-reveal {
          display: none;
        }

        &.yos-input-clear {
          padding-right: 21px;
        }

        &.yos-input-icon {
          padding-left: 40px;
        }

        &:focus {
          border-color: #1aad19;
        }

        &:read-only, &:disabled {
          background-color: #e5e5e5;

          &:focus {
            border-color: #ccc;
          }
        }
      }
    }
  }
</style>
