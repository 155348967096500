<template>
  <div class="yos-loading" v-if="show">
    <span class="loading-panel">
      <img class="loading-img" src="../../assets/images/loading.svg">
    </span>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const state = reactive({
      show: false
    })
    let timer

    watch(props, ({ loading, delay }) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        state.show = loading
      }, delay)
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="postcss">
.yos-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, .3);
  text-align: center;

  &.translate {
    background-color: rgba(255, 255, 255, 0);
  }

  .loading-panel {
    display: inline-block;
    vertical-align: middle;
  }

  .loading-img {
    vertical-align: top;
  }

  &:after{
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
