<template>
  <div :class="`yos-dialog ${activeClass}`">
    <div class="mask" @click="onMask"/>
    <div class="dialog-box">
      <i class="icon-close" @click="onClose" />
      <div v-if="state.title" class="title hairline-bottom">
        {{state.title}}
      </div>
      <div v-if="state.childrenComponent" class="content hairline-bottom">
        <component :is="state.childrenComponent"></component>
      </div>
      <div v-else-if="state.content" class="content hairline-bottom">
        <p v-for="(item, idx) in contentArr" :key="idx">{{ item }}</p>
      </div>
      <div v-else class="content hairline-bottom">
        <slot />
      </div>
      <div class="options" v-if="state.okText || state.cancelText">
        <yos-button
          class="yos-button-primary copy-button"
          v-if="state.okText"
          :data-clipboard-text="state.copyText"
          @click="onOk"
        >
          {{ state.okText }}
        </yos-button>
        <yos-button
          v-if="state.cancelText"
          @click="onCancel"
        >
          {{ state.cancelText }}
        </yos-button>
      </div>
    </div>
  </div>
</template>

<script>
import YosButton from '../form/yos-button'
import YosInput from '../form/yos-input'
import { getCurrentInstance, reactive, computed, ref } from 'vue'
import ClipboardJS from 'clipboard'
import { setState } from '../../util'
import { useToast } from '../../hook'

export default {
  name: 'YosDialog',
  components: {
    YosButton,
    YosInput
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    childrenComponent: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const text = ref('')
    const activeClass = ref('')
    let state = reactive({...props})
    const contentArr = computed(() => state.content.split('/n'))

    const onCopy = () => {
      const clipboard = new ClipboardJS(`.copy-button`)

      clipboard.on('success', function(e) {
        toast('已复制')
        e.clearSelection()
      })

      clipboard.on('error', function() {
        toast('复制失败')
      })
    }

    const open = () => {
      activeClass.value = 'open'
    }

    const close = () => {
      activeClass.value = ''
      state.className = ''
      state.title = ''
      state.content = ''
      state.okText = '确定'
      state.cancelText = '取消'
      state.childrenComponent = '',
      state.onOk = () => {}
      state.onClose = () => {}
    }

    const internalInstance = getCurrentInstance()
    internalInstance.appContext.config.globalProperties.$dialog = (data) => {
      setState(state, data)
      open()

      return {
        open,
        close
      }
    }

    const onClose = () => {
      emit('close')

      if (state.onClose) {
        const callback = state.onClose()
        if (callback) return
      }

      close()
    }

    const onMask = () => {
      emit('mask')
      onClose()
    }

    const onCancel = () => {
      emit('cancel')
      onClose()
    }

    const onOk = () => {
      emit('ok')

      if (state.onOk) {
        const callback = state.onOk(text.value)
        if (callback) return
      }

      state.copyText && onCopy()

      close()
    }

    return {
      text,
      state,
      activeClass,
      contentArr,
      open,
      onOk,
      onCancel,
      onClose,
      onMask,
    }
  }
}
</script>

<style lang="postcss">
.yos-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -100;
  opacity: 0;

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .dialog-box {
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.5);
    transition: transform .2s ease;
    word-break: break-word;
    overflow: hidden;

    .icon-close {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 10px;
      cursor: pointer;
    }

    .title {
      font-size: 16px;
      color: #333;
      padding: 30px;
    }

    .content {
      max-height: 60vh;
      overflow-y: auto;
      padding: 15px 30px;
      font-size: 14px;
      min-height: 80px;
      line-height: 2;
    }

    .options {
      text-align: center;
      padding: 20px;

      .yos-button {
        margin: 0 5px;
      }
    }
  }

  &.open {
    z-index: 200;
    opacity: 1;

    .mask {
      opacity: 1;
    }

    .dialog-box {
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
  }
}
</style>
