import { inject, computed, ref, getCurrentInstance } from 'vue'
import ChangePassword from './components/common/change-password'
import { useRoute, useRouter } from 'vue-router'
import { serialize, isExitRight, removeAccessToken } from './util'

export const useToast = () => {
  const internalInstance = getCurrentInstance()

  return internalInstance.appContext.config.globalProperties.$toast
}

export const useDialog = () => {
  const internalInstance = getCurrentInstance()

  return internalInstance.appContext.config.globalProperties.$dialog
}

export const usePage = (url = '', params = {}) => {
  const route = useRoute()
  const page = computed(() => parseInt(route.query.page) || 1)
  const baseUrl = computed(() => serialize(url, params))

  return {
    page: page.value,
    baseUrl: baseUrl.value
  }
}

export const useServer = (method, callback = () => {}) => {
  const router = useRouter()
  const toast = useToast()
  const dialog = useDialog()
  const updateStore = inject('updateStore')
  let load = () => {}, success = () => {}, fail = () => {}, data
  if (Object.prototype.toString.call(callback) === '[object Function]') {
    success = callback
  } else if (callback) {
    load = callback.load || (() => {})
    success = callback.success || (() => {})
    fail = callback.fail
  }

  return async (params) => {
    load({loading: true}, params)

    updateStore('loading', { loading: true, delay: 500 })

    data = await method(params)

    updateStore('loading', { loading: false })

    load({loading: false}, params)

    if (data && (data.code === 200 || data.code === 201)) {
      success(data, params)
    } else {
      if (data.code === 401) {
        removeAccessToken()
        router.push('/login')
        return
      }

      if (data.code === 403) {
        dialog({
          title: '提示',
          content: '您没有足够权限，请联系管理员',
          cancelText: ''
        })
        return
      }

      if (data.code === 700) {
        dialog({
          title: data.msg,
          childrenComponent: <ChangePassword data-type="first" />,
          okText: '',
          cancelText: '',
          onClose: () => true
        })
        return
      }

      if (data.code === 701) {
        dialog({
          title: '提示',
          content: data.msg,
          okText: '重新登录',
          cancelText: '',
          onOk: () => {
            removeAccessToken()
            router.push('/login')
          },
          onClose: () => true
        })
        return
      }

      fail(data, params)
      data && data.msg && toast(data.msg)
    }
  }
}

export const useTabNav = (nav = []) => {
  const tabNav = ref([])

  nav.forEach(item => {
    if (isExitRight(item.to)) {
      tabNav.value.push(item)
    }
  })

  return tabNav
}
