<template>
  <label :class="`yos-checkbox ${disabled ? 'yos-checkbox-disabled' : ''}`">
    <i :class="`icon-checkbox${trueValue === modelValue ? '-checked' : ''}`" />
    <span class="yos-checkbox-label" v-if="label">{{label}}</span>
    <input
      class="yos-checkbox-input"
      type="checkbox"
      :checked="trueValue === modelValue"
      :name="name"
      :disabled="disabled"
      @change="onChange"
    />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: [Boolean, Number, String],
    trueValue: {
      type: [Boolean, Number, String],
      default: true
    },
    falseValue: {
      type: [Boolean, Number, String],
      default: false
    },
    label: '',
    name: '',
    disabled: false
  },
  setup (props, { emit }) {
    const onChange = (event) => {
      emit('update:modelValue', event.target.checked ? props.trueValue : props.falseValue)
    }

    return {
      onChange
    }
  }
}
</script>

<style lang="postcss">
.yos-checkbox {
  position: relative;
  display: inline-block;
  line-height: 18px;

  .icon-checkbox, .icon-checkbox-checked {
    vertical-align: top;
    font-size: 18px;
    color: #1aad19;
    margin-right: 3px;
    user-select: none;
  }

  .yos-checkbox-label {
    display: inline-block;
    vertical-align: top;
  }

  .yos-checkbox-input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.yos-checkbox-disabled {
    .icon-checkbox, .icon-checkbox-checked, .yos-checkbox-label {
      color: #999;
    }
  }
}
</style>
