<template>
  <yos-toolbar v-if="state.toolbarShow" />
  <yos-main-menu v-if="state.menuShow" />
  <router-view />
  <yos-toast />
  <yos-dialog />
  <yos-loading :loading="loading.loading" :delay="loading.delay" />
</template>

<script>
import YosToolbar from './components/other/toolbar'
import YosMainMenu from './components/other/main-menu'
import YosToast from './components/other/yos-toast'
import YosDialog from './components/other/yos-dialog'
import YosLoading from './components/common/yos-loading'
import { provide, reactive, toRefs } from 'vue'
import { global } from './util'

export default {
  name: 'App',
  components: {
    YosToolbar,
    YosMainMenu,
    YosToast,
    YosDialog,
    YosLoading
  },
  setup() {
    const store = reactive({
      loading: {}
    })

    const updateStore = (name, value) => {
      store[name] = value
    }

    provide('store', store)
    provide('updateStore', updateStore)

    const state = reactive({})
    Object.defineProperty(global, 'state', {
      set: (obj) => {
        Object.keys(obj).forEach(key => {
          state[key] = obj[key]
        })
      }
    })

    return {
      state,
      ...toRefs(store)
    }
  }
}
</script>

<style lang="postcss">
[class^="icon-"], [class*=" icon-"] {
  line-height: inherit;
  font-size: 16px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

#app, textarea, input {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  font-family: -apple-system, "PingFang SC", "Microsoft YaHei", "Hiragino Sans GB", "SF UI Text", "Helvetica Neue", Arial, "WenQuanYi Zen Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

html {
  height: 100%;
  color: #555;
  background-color: #f6f8f9;
  font-size: 14px;
}

body {
  margin: 0;

  &.full-screen {
    height: 100%;

    #app {
      height: 100%;
    }

    [class^="page-"], [class*=" page-"] {
      height: 100%;
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
  }
}

div, p, span, strong, a, ul, ol, li, i, img, h2, h3, h4, h5, form, input, textarea {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul, ol, li {
  list-style: none;
}

* {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: top;
}

a {
  text-decoration: none;
  color: #666;
}

.fix {
  zoom: 1;

  &:after {
    display: block;
    content: '';
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }
}

.fl-l { float: left; }

.fl-r { float: right; }

.flex {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;

  .cell {
    -webkit-box-flex: 1;
    flex: 1;
    display: block;
    max-width: 100%;
    flex-basis: 0;
    min-width: 0; /* 必须加上min-width:0; 不然cell里如果使用了elis，则会溢出 */
  }

  &.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  &.item-c {
    -webkit-box-align: center;
    align-items: center;
  }

  &.jcc {
    -webkit-box-pack: center;
    justify-content: center;
  }

  &.jcsb {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

.elis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 多行省略号 */
.multi-elis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
}

.v-middle {
  text-align: center;

  .v-middle-content {
    display: inline-block;
    vertical-align: middle;
  }

  &:after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

[class^="page-"], [class*=" page-"] {
  padding: 80px 50px 20px 300px;
}

.p-card {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  padding: 20px;
  margin: 10px 0;

  .card-title {
    background-color: #f6f8f9;
    line-height: 40px;
    color: #555;
    padding: 0 10px;

    &.no-top {
      margin-top: 20px;
    }
  }

  .right-wrapper {
    margin-left: 80px;

    .yos-input-inner {
      width: 400px;
    }

    &.right-box {
      width: 500px;
      min-height: 300px;
      border: solid 1px #e5e5e5;
      padding: 10px 0;

      .roles {
        line-height: 30px;
        padding: 0 15px;
      }
    }
  }

  .left-fixed-item {
    line-height: 40px;
    margin-top: 10px;

    .left-fixed {
      width: 80px;
      float: left;
      text-align: right;
      padding: 0 10px;
    }
  }
}

.p-title {
  font-size: 26px;
  font-weight: 400;
  margin-top: 30px;
}

.overflow-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-auto::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: rgba(194, 194, 194, 1);
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-10 {
  margin: 10px;
}

.p-search-bar {
  padding: 20px 0;
  line-height: 40px;

  .yos-input {
    margin-right: 10px;
  }

  .yos-button {
    margin-right: 10px;
    vertical-align: top;
  }

  .yos-select {
    margin-right: 20px;
  }
}

.m-table {
  width: 100%;
  font-size: 14px;
  background-color: #fff;
  text-align: center;
  border-spacing: 0;
  border: none;

  thead {
    background-color: #f6f8f9;
    color: #9a9a9a;
  }

  tr {
    text-align: left;
  }

  th, td {
    line-height: 26px;
    border-bottom: 1px solid #e7e7eb;
    padding: 7px 10px;
    font-weight: 400;
    font-style: normal;
    word-wrap: break-word;
    word-break: break-all;
  }

  tbody td {
    padding: 10px;
  }

  .option {
    padding: 0 5px;
    cursor: pointer;
  }
}

.yos-select {
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 3px;
  height: 40px;
  line-height: 20px;
  vertical-align: top;
}
</style>
