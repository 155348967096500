<template>
  <div class="yos-toolbar">
    <div class="yos-toolbar-wrapper">
      <div class="yos-toolbar-logo">
        <img src="@/assets/images/logo.png">
      </div>
      <h4 class="yos-toolbar-title">
        <span>纵购公众号管理</span>
      </h4>
    </div>
    <user-info />
  </div>
</template>

<script>
import UserInfo from '../common/user-info'
export default {
  components: {
    UserInfo
  }
}
</script>

<style lang="postcss">
.yos-toolbar {
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(150,150,150,0.3);
  z-index: 10;

  .yos-toolbar-title {
    font-size: 18px;
    line-height: 40px;
    color: #555;
    margin-left: 20px;
    float: left;
    font-weight: normal;
  }

  .yos-toolbar-logo {
    width: 40px;
    height: 40px;
    float: left;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ui-user-info {
    float: right;
  }
}
</style>
