<template>
  <a
    v-if="href"
    :class="`yos-button`"
    :href="href"
  >
    <slot />
  </a>
  <router-link
    v-else-if="to"
    :class="`yos-button`"
    :to="to"
  >
    <slot />
  </router-link>
  <button
    v-else
    :class="`yos-button`"
    :type="type || 'button'"
  >
    <slot />
  </button>
</template>

<script>
import { toRefs } from 'vue'

export default {
  props: ['type', 'to', 'href'],
  setup(props) {
    const { type, to, href } = toRefs(props)

    return {
      type,
      to,
      href
    }
  }
}
</script>

<style lang="postcss">
.yos-button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  padding: 0 19px;
  background-color: #fff;
  font-size: 14px;
  color: #666;
  border: 1px solid #ccc;
  text-align: center;
  user-select: none;
  min-width: 36px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    border-color: #999;
    color: #333;
  }

  &.yos-button-disabled,
  &:disabled {
    border-color: transparent;
    color: #999;
    background: #e5e5e5;
    border-color: #ccc;
  }

  &.yos-button-primary {
    background-color: #34a123;
    border-color: #34a123;
    color: #fff;
    min-width: 36px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
  }

  &.yos-button-primary:hover {
    background-color: #1aad19;
    border-color: #1aad19;
    color: #fff;
  }

  &.yos-button-danger {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    color: #fff;
    min-width: 36px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
  }

  &.yos-button-danger:hover {
    background-color: #ff7875;
    border-color: #ff7875;
    color: #fff;
  }
}
</style>
