import axios from './axios'
import md5 from 'md5'
import { serialize } from './util.js'

const ajax = {}
const methods = ['get', 'post', 'put', 'patch', 'delete']

methods.forEach((item) => {
  ajax[item] = (url, data, config) => axios[item](url, data, config).then(res => res.data).catch(err => err)
})

/**
 * 获取授权参数
 */
export const getAuthorizationNeed = () => (
  ajax.post('/api/v1/authorizers/need')
)

/**
 * 获取授权方列表
 */
export const getAuthorizers = ({keyword, status, page, size}) => (
  ajax.get(serialize('/api/v1/authorizers', {keyword, status, page, size}))
)

/**
 * 获取公众号统计数据
 */
export const getAuthorizersCount = () => (
  ajax.get('/api/v1/authorizers/count')
)

/**
 * 更新授权公众号状态
 */
export const upateAuthorizerStatus = ({ id, status = 2 }) => (
  ajax.patch(`/api/v1/authorizers/${id}/status`, { status })
)

/**
 * 获取授权方信息
 */
export const getAuthorizer = ({ id }) => (
  ajax.get(`/api/v1/authorizers/${id}`)
)

/**
 * 同步授权方信息
 */
 export const syncAuthorizer = ({ id }) => (
  ajax.put(`/api/v1/authorizers/${id}/sync`)
)

/**
 * 更新授权方店铺id
 */
export const updateAuthorizerStoreId = ({ id, storeId, templateMessageRemark }) => (
  ajax.patch(`/api/v1/authorizers/${id}`, { storeId, templateMessageRemark })
)

/**
 * 获取用户增减数据
 */
export const getUserSummary = (authorizerAppid) => (
  ajax.get(`/api/v1/user_summary?authorizerAppid=${authorizerAppid}`)
)

export const getHome = () => (
  ajax.get(`/api/v1/getHome`)
)

/**
 * 获取token
 */
export const getToken = ({username, password}) => (
  ajax.post('/api/v1/token', null, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Basic ${window.btoa(encodeURIComponent(username) + ':' + md5(password))}`
    }
  })
)

/**
 * 删除token
 */
export const removeToken = () => (
  ajax.delete('/api/v1/token')
)

/**
 * 获取登录账号信息
 */
export const getUser = () => (
  ajax.get('/api/v1/user')
)

/**
 * 修改登录账号信息
 */
export const changePassword = ({password, newPassword}) => (
  ajax.patch('/api/v1/change_password', {
    password: md5(password),
    newPassword: md5(newPassword)
  })
)

/**
 * 获取账号列表
 */
export const getAccounts = ({keyword, page, size = 10}) => (
  ajax.get(serialize('/api/v1/accounts', {keyword, page, size}))
)

/**
 * 获取账号
 */
export const getAccount = ({id}) => (
  ajax.get(`/api/v1/accounts/${id}`)
)

/**
 * 创建账号
 */
export const addAccount = ({name, mobile, status, roleIds, authorizerAppids = ''}) => (
  ajax.post('/api/v1/accounts', {name, mobile, status, roleIds, authorizerAppids})
)

/**
 * 更新账号
 */
export const updateAccount = ({id, name, mobile, status, roleIds, authorizerAppids = ''}) => (
  ajax.put(`/api/v1/accounts/${id}`, {id, name, mobile, status, roleIds, authorizerAppids})
)

/**
 * 更新账号状态
 */
export const updateAccountStatus = ({id, status}) => (
  ajax.patch(`/api/v1/accounts/${id}`, {status})
)

/**
 * 重置账号密码
 */
export const resetAccountPassword = ({id}) => (
  ajax.patch(`/api/v1/accounts/${id}/rest_password`)
)

/**
 * 删除账号
 */
export const deleteAccount = ({id}) => (
  ajax.delete(`/api/v1/accounts/${id}`)
)

/**
 * 获取权限列表
 */
export const getRight = () => (
  ajax.get('/api/v1/right')
)

/**
 * 新增权限
 */
export const addRight = ({name, path, method, type, icon, sort, parentId, status}) => (
  ajax.post('/api/v1/right', {name, path, method, type, icon, sort, parentId, status})
)

/**
 * 更新权限
 */
export const updateRight = ({id, name, path, method, type, icon, sort, parentId, status}) => (
  ajax.put(`/api/v1/right/${id}`, {name, path, method, type, icon, sort, parentId, status})
)

/**
 * 删除权限
 */
export const deleteRight = ({id}) => (
  ajax.delete(`/api/v1/right/${id}`)
)

/**
 * 获取职责列表
 */
export const getRoles = ({keyword, page, size = 10}) => (
  ajax.get(serialize('/api/v1/roles', {keyword, page, size}))
)

/**
 * 获取职责
 */
export const getRole = ({id}) => (
  ajax.get(`/api/v1/roles/${id}`)
)

/**
 * 创建职责权限
 */
export const addRole = ({name, status, rightIds}) => (
  ajax.post('/api/v1/roles', {name, status, rightIds})
)

/**
 * 更新职责
 */
export const updateRole = ({id, status, rightIds}) => (
  ajax.put(`/api/v1/roles/${id}`, {status, rightIds})
)

/**
 * 禁用/启用职责
 */
export const updateRoleByStatus = ({id, status}) => (
  ajax.patch(`/api/v1/roles/${id}`, {status})
)

/**
 * 获取授权公众号关注用户列表
 */
export const getAuthorizerUsers = ({authorizerAppid, keyword = '', sync = 0, page = 1, size = 10}) => (
  ajax.get(serialize(`/api/v1/authorizers/${authorizerAppid}/users`, {keyword, sync, page, size}))
)

/**
 * 获取授权公众号模板列表
 */
export const getTemplates = ({authorizerAppid}) => (
  ajax.get(`/api/v1/authorizers/${authorizerAppid}/fun/templates`)
)

/**
 * 从模版库添加公众号模板
 */
export const addTemplates = ({authorizerAppids, libraryIds, keywordNamelist}) => (
  ajax.post(`/api/v1/fun/templates`, {authorizerAppids,libraryIds, keywordNamelist})
)

/**
 * 删除授权公众号模板
 */
export const deleteTemplate = ({authorizerAppid, templateId}) => (
  ajax.delete(`/api/v1/authorizers/${authorizerAppid}/fun/templates/${templateId}`)
)

/**
 * 授权公众号发送模板消息
 */
export const sendTemplate = (authorizerAppid) => (
  ajax.get(`/api/v1/authorizers/${authorizerAppid}/fun/template_send`)
)

/**
 * 模板库模板列表
 */
export const getTemplateLibraries = ({keyword, page, size}) => (
  ajax.get(serialize(`/api/v1/fun/template_libraries`, {keyword, page, size}))
)

/**
 * 模板库模板详情
 */
export const getTemplateLibrary = ({id}) => (
  ajax.get(`/api/v1/fun/template_libraries/${id}`)
)

/**
 * 新增模板库模板
 */
export const addTemplateLibrary = ({id, title, remark, keywords}) => (
  ajax.post(`/api/v1/fun/template_libraries`, {id, title, remark, keywords})
)

/**
 * 更新模板库模板
 */
export const updateTemplateLibrary = ({id, title, remark, keywords}) => (
  ajax.put(`/api/v1/fun/template_libraries/${id}`, {title, remark, keywords})
)

/**
 * 删除模板库模板
 */
export const deleteTemplateLibrary = ({id}) => (
  ajax.delete(`/api/v1/fun/template_libraries/${id}`)
)

/**
 * 推送模板列表
 */
export const getSendTemplates = ({keyword, authorizer, platform = 1, page, size = 10}) => (
  ajax.get(serialize(`/api/v1/fun/send_templates`, {keyword, authorizer, platform, page, size}))
)

/**
 * 推送模板详情
 */
export const getSendTemplate = ({id}) => (
  ajax.get(`/api/v1/fun/send_templates/${id}`)
)

/**
 * 新增推送模板
 */
export const addSendTemplate = ({platform = 1, libraryId, libraryTitle, authorizerAppids, status = 1}) => (
  ajax.post(`/api/v1/fun/send_templates`, {platform, libraryId, libraryTitle, authorizerAppids, status})
)

/**
 * 获取公众号模版信息
 */
export const getTemplateInfo = ({libraryId, authorizerAppids}) => (
  ajax.get(`/api/v1/fun/templates/${libraryId}?authorizerAppids=${authorizerAppids}`)
)

/**
 * 更新推送模板
 */
export const updateSendTemplate = ({id, platform = 1, libraryId, libraryTitle, authorizerAppids, status = 1}) => (
  ajax.put(`/api/v1/fun/send_templates/${id}`, {platform, libraryId, libraryTitle, authorizerAppids, status})
)

/**
 * 推送模板消息
 */
export const sendTemplateMessage = (storeId, libraryId) => (
  // ajax.post(`/api/v1/wx/${storeId}/receive`)
  ajax.post(`/api/v1/fun/${storeId}/templates/${libraryId}/send`, {
    unionid: 'oRXn5wbo8scuzGEwQ383pLDpYHww',
    url: 'http://www.yos.com',
    data: {
      first: {
        value: '这是来自李さん的退款提示测试😄❤',
        color: '#173177'
      },
      reason: {
        value: '退款原因',
        color: '#173177'
      },
      refund: {
        value: '退款金额',
        color: '#00ff00'
      },
      remark: {
        value: '详情请点击此消息进入会员中心-余额变更记录进行查询!',
        color: '#173177'
      }
    }
  }, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ba3e983e3c14651737e720a60818649f`
    }
  })
)

