const storage = {getItem: function() {}, setItem: function() {}, removeItem: function() {}}

export const sessionStorage = window.sessionStorage || storage
// 安卓版微信可能因为内存不足的原因，把localStorage放到缓存里而不能持久保存，http://www.cnblogs.com/mmmjiang13/p/4141557.html
export const localStorage = {
  getItem: function(name) {
    return window.localStorage && window.localStorage.getItem(name)
  },
  setItem: function(name, value) {
    window.localStorage && window.localStorage.setItem(name, value)
  },
  removeItem: function(name) {
    window.localStorage && window.localStorage.removeItem(name)
  },
  clear: function() {
    window.localStorage && window.localStorage.clear()
  }
}

export const tryJson = (name, value, defaultValue) => {
  let json
  try {
    json = JSON[name](value) || defaultValue
  } catch (e) {
    json = defaultValue || null
  }
  return json
}

const tokenName = window.btoa('access_token')
export const getAccessToken = () => localStorage.getItem(tokenName)

export const setAccessToken = (value) => {
  localStorage.setItem(tokenName, value)
}

export const removeAccessToken = () => {
  localStorage.removeItem(tokenName)
  localStorage.removeItem('user')
}

export const getLocalUser = () => tryJson('parse', localStorage.getItem('user'), {})
export const setLocalUser = (user) => user && localStorage.setItem('user', tryJson('stringify', user, {}))

export const isExitRight = (path, method = 'GET') => {
  const { rights } = getLocalUser()

  return (rights || []).find(item => item.method === method && item.path === path) !== undefined
}

// 序列化参数，创建 URL 编码文本字符串。
export const serialize = (baseUrl = '', params = {}) => {
  let query = ''
  Object.keys(params).forEach(entry => {
    if (params[entry] !== null && params[entry] !== undefined && params[entry] !== '') {
      query += `${!query ? '' : '&'}${entry}=${params[entry]}`
    }
  })

  return baseUrl + (/\?/.test(baseUrl) ? (/\?$/.test(baseUrl) ? '' : '&') : '?') + query
}

export const setFullScreen = (isFull) => {
  document.body.className = isFull ? 'full-screen' : ''
}

export const addClass = (...params) => (
  params.filter(item =>
    (Array.isArray(item) && item[0] && typeof item[1] === 'string' && (item[1] || '').trim() !== '') ||
    (typeof item === 'string' && (item || '').trim() !== '')
  )
    .map(item => (
      Array.isArray(item) ? (item[0] ? item[1] : item[2] || '') : (item || '').trim()
    ))
    .join(' ')
)

export const convertState = (props, state) => {
  const newProps = state || {}
  Object.keys(props).forEach(key => {
    newProps['state' + key.substr(0, 1).toUpperCase() + key.substr(1)] = props[key]
  })
  return newProps
}

export const setState = (state = {}, data = {}) => {
  const newData = state
  Object.keys(data).forEach(key => {
    newData[key] = data[key]
  })
  return newData
}

export const global = {}
export const globalRoute = {}

export const getPageNavs = (name) => {
  const navs = {
    fun: [{
      name: '推送模板',
      to: '/fun/send_templates'
    }, {
      name: '公众号模版',
      to: '/fun/templates'
    }, {
      name: '模板库',
      to: '/fun/template_libraries'
    }],
    manage: [{
      name: '已关注',
      to: '/manage/follow'
    }]
  }

  return navs[name]
}

export const gotToWeixin = (data) => {
  const redirectUri = encodeURIComponent(data.redirectUri + '&from=' + window.location.pathname)
  window.location.href = `https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=${data.appId}&pre_auth_code=${data.preAuthCode}&redirect_uri=${redirectUri}`
}
