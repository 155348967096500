<template>
  <div
    v-if="message"
    :class="`yos-toast ${activeClass}`"
  >
    {{ message }}
  </div>
  <div
    v-else
    :class="`yos-toast ${activeClass}`"
  >
    <slot />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
let timer

export default {
  name: 'YosToast',
  props: {
    msg: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 3000
    }
  },
  setup(props) {
    const message = ref(props.msg)
    const activeClass = ref('')

    const open = (msg) => {
      message.value = msg
      activeClass.value = 'open'
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        activeClass.value = ''
        message.value = ''
      }, props.duration)
    }

    const internalInstance = getCurrentInstance()
    internalInstance.appContext.config.globalProperties.$toast = open

    return {
      activeClass,
      open,
      message
    }
  }
}
</script>

<style lang="postcss">
.yos-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(.5);
  transition: all .2s ease;
  word-break: break-word;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  z-index: -100;
  opacity: 0;

  &.open {
    z-index: 5100;
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
</style>
